import { useContext } from 'react'
import { PageContext, PageContextProps } from '../templates/page'
import { PostContext, PostContextProps } from '../templates/post'
import { VacancyContext, VacancyContextProps } from '../templates/vacancy'

export type { PageContextProps, PostContextProps }

const usePage = (): PostContextProps | PageContextProps => {
  const postContext = useContext(PostContext)
  const vacancyContext = useContext(VacancyContext)

  if (postContext.exists) {
    return postContext as PostContextProps
  }

  if (vacancyContext.exists) {
    return vacancyContext as PostContextProps
  }

  const pageContext = useContext(PageContext)

  return pageContext as PageContextProps
}

export default usePage
