import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Styled
import { FormCSS } from 'styles/form'

// Images
import { FormProps } from './FormShell'
import usePage from 'hooks/usePage'

const Section = styled.div`
  z-index: 5;
`

const StyledFormDuuf = styled(FormDuuf)`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  z-index: 2;
  padding: 5rem 2rem;
  ${FormCSS}
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.light};
  }
`

const Details = styled.div`
  & svg {
    width: 20px;
    height: 20px;
    margin-right: 7.5px;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }

  & a:hover {
    text-decoration: underline;
  }
`

const FormVacancy: React.FC<FormProps> = ({ fields }) => {
  const vacancy = usePage()

  console.log('v', vacancy)

  return (
    <Section className="position-relative">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <StyledFormDuuf
              scrollToConfirm={false}
              id={2}
              className="position-relative mt-5"
              generate={false}
              privacyUrl="/privacy-policy/"
              privacyUrlPlacement="privacy policy"
              defaultFields={{ input_8: vacancy.title || 'Vacancy' }}
            >
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <Content content={fields.description} className="mb-5" />
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <FormDuufGroup hideIds={[2, 3, 6, 7]} />
                    </div>
                    <div className="col-lg-5 d-flex flex-column justify-content-between">
                      <div>
                        <FormDuufGroup showIds={[2, 3, 6, 7]} />
                      </div>
                    </div>
                    <FormDuufSubmit />
                  </div>
                </div>
              </div>
            </StyledFormDuuf>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FormVacancy
